import AssetProDark from "@/common/assets/image/products/icons/AssetProDark.svg"
import ComplianceAPIDark from "@/common/assets/image/products/icons/ComplianceAPIDark.svg"
import ComplianceLiteDark from "@/common/assets/image/products/icons/ComplianceLiteDark.svg"
import ComplianceProDark from "@/common/assets/image/products/icons/ComplianceProDark.svg"
import ComplyFlowDark from "@/common/assets/image/products/icons/ComplyFlowDark.svg"
import NFTBoostDark from "@/common/assets/image/products/icons/NFTBoostDark.svg"
import OAuthSDKDark from "@/common/assets/image/products/icons/OAuthSDKDark.svg"
import User360Dark from "@/common/assets/image/products/icons/User360Dark.svg"
import WalletAPIDark from "@/common/assets/image/products/icons/WalletAPIDark.svg"
import WalletAPPDark from "@/common/assets/image/products/icons/WalletAPPDark.svg"
import WalletBuilderDark from "@/common/assets/image/products/icons/WalletBuilderDark.svg"
import WalletExtensionDark from "@/common/assets/image/products/icons/WalletExtensionDark.svg"
import WalletSDKDark from "@/common/assets/image/products/icons/WalletSDKDark.svg"

export interface NavMenuItem {
  id?: string
  icon?: string
  label: string
  route: string
  localizedRoute?: {
    locale: string
    href: string
    target?: "_blank" | "_self"
  }[]
  target?: "_blank" | "_self"
  disabled?: boolean
}

export interface NavMenuItemGroup {
  label?: string
  items: NavMenuItem[]
}

export interface NavMenuItemColumn {
  groups: NavMenuItemGroup[]
}

export interface NavMenu {
  id: string
  category: string
  href: string
  itemColumn: NavMenuItemColumn[]
}

export const productCustomerNav: NavMenuItemColumn[] = [
  {
    groups: [
      {
        label: "navbar.product.apps.title",
        items: [
          {
            icon: WalletExtensionDark.src,
            label: "navbar.product.wallets.extension",
            id: "navbar-product-wallet-extension",
            route: "/products/wallet-extension"
          },
          {
            icon: WalletAPPDark.src,
            label: "navbar.product.wallets.app",
            id: "navbar-product-wallet",
            route: "/products/wallet"
          }
        ]
      }
    ]
  }
]

export const navMenu: NavMenu[] = [
  {
    id: "Products",
    category: "navbar.Product",
    href: "#",
    itemColumn: [
      {
        groups: [
          {
            label: "navbar.product.analytics.title",
            items: [
              {
                icon: User360Dark.src,
                label: "navbar.product.analytics.user360",
                id: "navbar-product-user360",
                route: "/products/user360"
              }
            ]
          },
          {
            label: "navbar.product.assetManagement.title",
            items: [
              {
                icon: AssetProDark.src,
                label: "navbar.product.assetManagement.assetPro",
                id: "navbar-product-assetPro",
                route: "/products/asset-pro"
              }
            ]
          },
          {
            label: "navbar.product.onboarding.title",
            items: [
              {
                icon: OAuthSDKDark.src,
                label: "navbar.product.onboarding.oauthSDK",
                id: "navbar-product-oauth-sdk",
                route: "/products/oauth-sdk"
              }
            ]
          }
        ]
      },
      {
        groups: [
          {
            label: "navbar.product.wallets.title",
            items: [
              {
                icon: WalletBuilderDark.src,
                label: "navbar.product.wallets.wlws",
                id: "navbar-product-wallet-builder",
                route: "/products/wallet-builder"
              },
              {
                icon: WalletSDKDark.src,
                label: "navbar.product.wallets.sdk",
                id: "navbar-product-wallet-sdk",
                route: "/products/wallet-sdk"
              },
              {
                icon: WalletAPIDark.src,
                label: "navbar.product.wallets.api",
                id: "navbar-product-wallet-api",
                route: "/products/wallet-api"
              }
            ]
          },
          {
            label: "navbar.product.nft.title",
            items: [
              {
                icon: NFTBoostDark.src,
                label: "navbar.product.nft.boost",
                id: "navbar-product-nft-boost",
                route: "/products/nft-boost"
              }
            ]
          }
        ]
      },
      {
        groups: [
          {
            label: "navbar.product.compliance.title",
            items: [
              {
                icon: ComplianceProDark.src,
                label: "navbar.product.compliance.pro",
                id: "navbar-product-compliance-pro",
                route: "/products/compliance-pro"
              },
              {
                icon: ComplianceLiteDark.src,
                label: "navbar.product.compliance.lite",
                id: "navbar-product-compliance-lite",
                route: "/products/compliance-lite"
              },
              {
                icon: ComplianceAPIDark.src,
                label: "navbar.product.compliance.api",
                id: "navbar-product-compliance-api",
                route: "/products/compliance-api"
              },
              {
                icon: ComplyFlowDark.src,
                label: "navbar.product.compliance.complyFlow",
                id: "navbar-product-comply-flow",
                route: "/products/comply-flow"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: "Solutions",
    category: "navbar.Solutions",
    href: "/pricing/compliance",
    itemColumn: [
      {
        groups: [
          {
            label: "navbar.solutions.industry.title",
            items: [
              {
                label: "navbar.solutions.industry.gamefi",
                id: "navbar-solution-gamefi",
                route: "/solutions/gamefi"
              },
              // {
              //   label: "navbar.solutions.industry.ecommerce",
              //   route: "/solutions",
              //   disabled: true
              // },
              // {
              //   label: "navbar.solutions.industry.entertainment",
              //   route: "/solutions",
              //   disabled: true
              // },
              {
                label: "navbar.solutions.industry.defi",
                id: "navbar-solution-defi",
                route: "/solutions/defi"
              }
            ]
          }
        ]
      },
      {
        groups: [
          {
            label: "navbar.solutions.useCase.title",
            items: [
              {
                label: "navbar.solutions.useCase.web3FundManagement",
                route: "/solutions/web3-fund-management"
              },
              {
                label: "navbar.solutions.useCase.web3Commerce",
                route: "/web3-commerce",
                disabled: true
              }

              // {
              //   label: "navbar.solutions.useCase.onboarding",
              //   route: "/products",
              //   disabled: true
              // },
              // {
              //   label: "navbar.solutions.useCase.offerNFT",
              //   route: "/products",
              //   disabled: true
              // },
              // {
              //   label: "navbar.solutions.useCase.payroll",
              //   route: "/products",
              //   disabled: true
              // },
              // {
              //   label: "navbar.solutions.useCase.engage",
              //   route: "/products",
              //   disabled: true
              // }
            ]
          }
        ]
      }
    ]
  },
  {
    id: "Resources",
    category: "navbar.Resources",
    href: "#",
    itemColumn: [
      {
        groups: [
          {
            label: "navbar.resources.general.title",
            items: [
              {
                label: "navbar.resources.general.blog",
                route: "/blog",
                id: "navbar-users-wallet-download",
                target: "_blank"
              },
              {
                label: "navbar.resources.general.docs",
                id: "navbar-company-docs",
                route: "https://www.kryptogo.com/docs/overview",
                localizedRoute: [
                  {
                    locale: "tw",
                    href: "https://www.kryptogo.com/docs/zh-TW/overview",
                    target: "_blank"
                  }
                ],
                target: "_blank"
              },
              {
                label: "navbar.resources.general.press",
                route: "/press",
                id: "navbar-users-wallet-roadmap",
                target: "_blank"
              },
              {
                label: "navbar.resources.general.systemStatus",
                route: "https://status.kryptogo.com/",
                id: "navbar-users-wallet-support",
                target: "_blank"
              }
            ]
          }
        ]
      },
      {
        groups: [
          {
            label: "navbar.resources.studio.title",
            items: [
              {
                label: "navbar.resources.studio.changeLog",
                route:
                  "https://www.kryptogo.com/docs/category/%EF%B8%8F-change-logs",
                localizedRoute: [
                  {
                    locale: "tw",
                    href: "https://www.kryptogo.com/docs/zh-TW/category/%EF%B8%8F-change-logs"
                  }
                ],
                id: "navbar-users-studio",
                target: "_blank"
              }
            ]
          },
          {
            label: "navbar.resources.wallet.title",
            items: [
              {
                label: "navbar.resources.wallet.roadmap",
                route: "https://roadmap.kryptogo.com/",
                id: "navbar-users-wallet-roadmap",
                target: "_blank"
              },
              {
                label: "navbar.resources.wallet.support",
                route: "https://support.kryptogo.com/",
                id: "navbar-users-wallet-support",
                target: "_blank"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: "Company",
    category: "navbar.company",
    href: "#",
    itemColumn: [
      {
        groups: [
          {
            label: "navbar.company.about-us.title",
            items: [
              {
                label: "navbar.company.aboutUs",
                id: "navbar-company-aboutUs",
                route: "/about-us"
              },
              {
                label: "navbar.company.partners",
                id: "navbar-company-partners",
                route: "/partners"
              },
              {
                label: "navbar.company.news",
                id: "navbar-company-news",
                route: "/events"
              },
              {
                label: "navbar.company.clients",
                id: "navbar-company-clients",
                route: "/clients"
              },
              {
                label: "navbar.company.career",
                id: "navbar-company-career",
                route: "/join-us"
              }
            ]
          }
        ]
      },
      {
        groups: [
          {
            label: "navbar.company.legal.title",
            items: [
              {
                label: "navbar.company.privacy",
                id: "navbar-company-privacy",
                route: "/privacy"
              },
              {
                label: "navbar.company.termsOfUse",
                id: "navbar-company-status",
                route: "/terms-of-service"
              }
            ]
          }
        ]
      }
    ]
  }
]
